exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-activation-js": () => import("./../../../src/pages/activation.js" /* webpackChunkName: "component---src-pages-activation-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-reset-password-js": () => import("./../../../src/pages/reset-password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-success-freespin-js": () => import("./../../../src/pages/success-freespin.js" /* webpackChunkName: "component---src-pages-success-freespin-js" */),
  "component---src-pages-world-cup-2022-js": () => import("./../../../src/pages/world-cup-2022.js" /* webpackChunkName: "component---src-pages-world-cup-2022-js" */),
  "component---src-templates-category-paginated-js": () => import("./../../../src/templates/category/paginated.js" /* webpackChunkName: "component---src-templates-category-paginated-js" */),
  "component---src-templates-category-promotion-js": () => import("./../../../src/templates/category/promotion.js" /* webpackChunkName: "component---src-templates-category-promotion-js" */),
  "component---src-templates-games-game-js": () => import("./../../../src/templates/games/game.js" /* webpackChunkName: "component---src-templates-games-game-js" */),
  "component---src-templates-page-banking-js": () => import("./../../../src/templates/page/banking.js" /* webpackChunkName: "component---src-templates-page-banking-js" */),
  "component---src-templates-page-contact-js": () => import("./../../../src/templates/page/contact.js" /* webpackChunkName: "component---src-templates-page-contact-js" */),
  "component---src-templates-page-game-page-js": () => import("./../../../src/templates/page/game-page.js" /* webpackChunkName: "component---src-templates-page-game-page-js" */),
  "component---src-templates-page-live-casino-js": () => import("./../../../src/templates/page/live-casino.js" /* webpackChunkName: "component---src-templates-page-live-casino-js" */),
  "component---src-templates-page-live-score-js": () => import("./../../../src/templates/page/live-score.js" /* webpackChunkName: "component---src-templates-page-live-score-js" */),
  "component---src-templates-page-lottery-js": () => import("./../../../src/templates/page/lottery.js" /* webpackChunkName: "component---src-templates-page-lottery-js" */),
  "component---src-templates-page-profile-js": () => import("./../../../src/templates/page/profile.js" /* webpackChunkName: "component---src-templates-page-profile-js" */),
  "component---src-templates-page-register-js": () => import("./../../../src/templates/page/register.js" /* webpackChunkName: "component---src-templates-page-register-js" */),
  "component---src-templates-page-regular-js": () => import("./../../../src/templates/page/regular.js" /* webpackChunkName: "component---src-templates-page-regular-js" */),
  "component---src-templates-page-sbobet-agent-js": () => import("./../../../src/templates/page/sbobet_agent.js" /* webpackChunkName: "component---src-templates-page-sbobet-agent-js" */),
  "component---src-templates-page-sbobet-slot-js": () => import("./../../../src/templates/page/sbobet_slot.js" /* webpackChunkName: "component---src-templates-page-sbobet-slot-js" */),
  "component---src-templates-page-slot-page-js": () => import("./../../../src/templates/page/slot-page.js" /* webpackChunkName: "component---src-templates-page-slot-page-js" */),
  "component---src-templates-page-virtual-sports-js": () => import("./../../../src/templates/page/virtual-sports.js" /* webpackChunkName: "component---src-templates-page-virtual-sports-js" */),
  "component---src-templates-page-withdraw-js": () => import("./../../../src/templates/page/withdraw.js" /* webpackChunkName: "component---src-templates-page-withdraw-js" */),
  "component---src-templates-post-post-js": () => import("./../../../src/templates/post/post.js" /* webpackChunkName: "component---src-templates-post-post-js" */),
  "component---src-templates-types-tag-js": () => import("./../../../src/templates/types/tag.js" /* webpackChunkName: "component---src-templates-types-tag-js" */)
}

